// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-collection-16-index-jsx": () => import("./../../../src/pages/collection16/index.jsx" /* webpackChunkName: "component---src-pages-collection-16-index-jsx" */),
  "component---src-pages-collection-17-index-jsx": () => import("./../../../src/pages/collection17/index.jsx" /* webpackChunkName: "component---src-pages-collection-17-index-jsx" */),
  "component---src-pages-collection-18-index-jsx": () => import("./../../../src/pages/collection18/index.jsx" /* webpackChunkName: "component---src-pages-collection-18-index-jsx" */),
  "component---src-pages-events-index-jsx": () => import("./../../../src/pages/events/index.jsx" /* webpackChunkName: "component---src-pages-events-index-jsx" */),
  "component---src-pages-influencerlove-index-jsx": () => import("./../../../src/pages/influencerlove/index.jsx" /* webpackChunkName: "component---src-pages-influencerlove-index-jsx" */),
  "component---src-pages-previous-index-jsx": () => import("./../../../src/pages/previous/index.jsx" /* webpackChunkName: "component---src-pages-previous-index-jsx" */),
  "component---src-pages-shipping-policy-jsx": () => import("./../../../src/pages/shippingPolicy.jsx" /* webpackChunkName: "component---src-pages-shipping-policy-jsx" */),
  "component---src-pages-stockists-index-jsx": () => import("./../../../src/pages/stockists/index.jsx" /* webpackChunkName: "component---src-pages-stockists-index-jsx" */),
  "component---src-templates-cart-index-jsx": () => import("./../../../src/templates/cart/index.jsx" /* webpackChunkName: "component---src-templates-cart-index-jsx" */),
  "component---src-templates-catalog-index-jsx": () => import("./../../../src/templates/catalog/index.jsx" /* webpackChunkName: "component---src-templates-catalog-index-jsx" */),
  "component---src-templates-main-index-jsx": () => import("./../../../src/templates/main/index.jsx" /* webpackChunkName: "component---src-templates-main-index-jsx" */),
  "component---src-templates-policy-index-jsx": () => import("./../../../src/templates/policy/index.jsx" /* webpackChunkName: "component---src-templates-policy-index-jsx" */),
  "component---src-templates-product-index-jsx": () => import("./../../../src/templates/product/index.jsx" /* webpackChunkName: "component---src-templates-product-index-jsx" */)
}

